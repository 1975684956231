<template>
  <div>
    <!-- <line-chart :isDetail="true" :pointData =data titleName=""  xAxisName="Date"  yAxisName="Value"></line-chart> -->
    <template v-if="isloading == true">
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-col class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <v-card v-else>
      <v-card-title>
        {{ this.title }}
        <v-btn
          text
          color="primary"
          class="ml-auto"
          :href="csvURL"
          target="_blank"
        >
          <span>Download</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="search"
      ></v-data-table>
    </v-card>
  </div>
</template>

<script>
//   import LineChart from "@/components/chart/ApexChart/LineChart.vue";

export default {
  components: {
    //   LineChart,
  },
  data() {
    return {
      csvURL: "",
      title: "",
      notes: "",
      search: "",
      id: "",
      source: "",
      // url: "http://192.168.50.3:9012/grocery/v1/",
      headers: [],
      data: [],
      isloading: true,
      isHeaderSet: false,
    };
  },
  methods: {
    viewFile(url) {
      console.log("VIEW");
      // this.isloading = true;
      this.$papa.parse(url, {
        download: true,
        header: true,
        dynamicTyping: true,
        step: (results) => {
          // Use an arrow function to preserve the outer context
          this.data.push(results.data);
          if (!this.isHeaderSet) {
            const nameArray = Object.keys(this.data[0]);
            this.headers = nameArray.map((element) => {
              return { text: element, value: element };
            });
            this.isHeaderSet = true;
            this.isloading = false;
          }
        },
      });
    },
  },
  created() {
    this.csvURL = this.$route.params.url;
    console.log(this.csvURL);
    this.title = this.$route.params.title;
    this.viewFile(this.csvURL);
  },
};
</script>

<style></style>
